import React from 'react';
import './../assets/css/components/_title.css';

export default function Title(props) {
  return (
    <div className="title">
      <h1>
        {props.text}
      </h1>
      <span></span>
    </div>
  );
}