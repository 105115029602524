import React, {useState} from 'react';
import './../assets/css/pages/_price-request.css';
import Logo from './../assets/img/logo2.svg';
import Title from '../components/Title';
import Input from '../components/Input';
import Textarea from '../components/Textarea';
import Button from '../components/Button';
import Radiobutton from '../components/Radiobutton';
import Error from '../components/Error';
import Loader from '../components/Loader';

export default function PriceRequest() {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [kmkr, setKmkr] = useState('Ei');
  const [personelCount, setPersonelCount] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [info, setInfo] = useState('');

  const handleSend = () => {
    setLoader(true);
    fetch('https://api.lindlin.ee/email/send', {
      method: 'POST',
      body: JSON.stringify({ name, code, email, kmkr, personelCount, invoiceCount, info }),
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setTimeout(() => {
          if (data.message === 'Error') {
            setError(true);
          } else {
            setSuccess(true);
            setError(false);
          }
          setLoader(false);
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoader(false);
          setSuccess(false);
          setError(true);
        }, 1000);
      });
  };

  return (
    <div className="page__price-request">
      <div className="page__container">
        <img src={Logo} alt="Lindlin Finance OÜ Logo" />
        <Title text="Hinnapäring" />
        {error && (<Error text="Päringu saatmine ebaõnnestus!" />)}
        {!success ? (
          <form style={{ 'display': 'flex', 'flexDirection': 'column' }}>
            <Input type="text" label="Ettevõtte nimi" onChange={e => setName(e.target.value)} />
            <Input type="text" label="Ettevõtte registrikood" onChange={e => setCode(e.target.value)} />
            <Input type="email" label="E-posti aadress" onChange={e => setEmail(e.target.value)} />
            <Radiobutton label="Käibemaksukohuslane" options={['Ei', 'Jah']} onChange={value => setKmkr(value)} />
            <Input type="text" label="Töötajate arv" onChange={e => setPersonelCount(e.target.value)} />
            <Input type="number" label="Keskmine arvete arv kuus" min={0} onChange={e => setInvoiceCount(e.target.value)} />
            <Textarea label="Lisainfo" rows={4} onChange={e => setInfo(e.target.value)} />
            <div style={{ 'display': 'flex', 'justifyContent': 'end' }}>
              <Button text="Saada" onClick={() => handleSend()} />
            </div>
          </form>
        ) : (
          <div style={{
            'display': 'flex',
            'justifyContent': 'center',
            'fontSize': '16px',
            'fontWeight': '500',
            'color': '#95c29f',
            'textAlign': 'center'
          }}>Päring saadetud!<br/>Vastame teile esimesel võimalusel.</div>
        )}
      </div>
      <Loader show={loader} />
    </div>
  );
}