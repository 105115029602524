import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

// Styles
import './assets/css/index.css';
import './assets/css/typography.css';

// Pages
import PriceRequest from './pages/PriceRequest';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<PriceRequest />} />
      </Routes>
    </Router>
  );
}

export default App;
