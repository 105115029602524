import React, {useState} from 'react';
import './../assets/css/components/_radiobutton.css';

const Radio = (props) => {
  return (
    <label className="container">{props.text}
      <input type="radio" checked={props.checked} onChange={() => props.onChange(props.index)}/>
      <span className="checkmark"></span>
    </label>
  );
};

export default function Radiobutton(props) {
  const [checkedIndex, setCheckedIndex] = useState(0);
  return (
    <div className="radiobutton">
      {props.label && (
        <label className="radiobutton__label">{props.label}</label>
      )}
      {props.options && props.options.map((option, index) => (
        <Radio
          key={`radiobutton-${index}`}
          index={index}
          text={option}
          checked={index === checkedIndex ? 'checked' : ''}
          onChange={newIndex => {
            setCheckedIndex(newIndex);
            props.onChange(option);
          }}
        />
      ))}
    </div>
  );
}