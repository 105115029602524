import React from 'react';
import './../assets/css/components/_button.css';

export default function Button(props) {
  return (
    <button
      {...props}
      type="button"
      className="button"
    >{props.text}</button>
  );
}