import React from 'react';
import './../assets/css/components/_input.css';

export default function Input(props) {
  return (
    <div className="input">
      {props.label && (
        <label>{props.label}</label>
      )}
      <input
        {...props}
        className="input"
        type={props.type ?? 'text'}
        spellCheck="false"
      />
    </div>
  );
}