import React from 'react';
import './../assets/css/components/_loader.css';

export default function Loader(props) {
  return (
    props.show && (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    )
  );
}