import React from 'react';
import './../assets/css/components/_textarea.css';

export default function Textarea(props) {
  return (
    <div className="textarea">
      {props.label && (
        <label>{props.label}</label>
      )}
      <textarea
        {...props}
        className="textarea"
        spellCheck="false"
      ></textarea>
    </div>
  );
}